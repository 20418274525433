<template>
    <div>
        <FormLanguage :selected="language" @languageChange="changeLanguage" class="mb-3" />
        <div v-for="(form, index) in forms" :key="index" class="mb-3">
            <label :for="index" class="form-label">
                <span>{{$t("title", {en: form.title.en, th: form.title.th})}}</span>
                <span v-if="isTextForm(form.type)" class="text-uppercase">&nbsp;({{language}})</span>
            </label>
            <input v-if="form.type === 'input'" v-model="form.value[language]" :id="index" type="text" class="form-control">
            <ImageUpload v-else-if="form.type === 'image'" :bannerUrl="article.banner.url" @fileChange="setBannerImage"/>
            <textarea v-else-if="form.type === 'textarea'" v-model="form.value[language]" :id="index" rows="15" class="form-control"></textarea>
            <CKEditor v-else-if="form.type === 'ckeditor'" v-model="form.value[language]" :editor="editor.type" :config="editor.config"></CKEditor>
        </div>
        <div class="row align-items-center mt-4">
            <div class="col">
                <ul class="fa-ul small text-muted">
                    <li v-for="(validator, index) in validation" :key="index" class="d-flex align-items-center">
                        <span v-if="validator.isValid" class="fa-li d-flex">
                            <i class="fas fa-check my-auto text-success"></i>
                        </span>
                        <span v-else class="fa-li d-flex">
                            <i class="fas fa-times my-auto"></i>
                        </span>
                        <span :class="{'text-success': validator.isValid}">{{$t("title", {en: validator.title.en, th: validator.title.th})}}</span>
                    </li>
                </ul>
            </div>
            <div class="col-6 small">
                <div class="row">
                    <div class="col">
                        <button @click="closeForm()" class="custom-btn btn-outline-grey p-3 rounded-1 w-100">
                            {{$t("cancelButton")}}
                        </button>
                    </div>
                    <div v-if="!isCreator" class="col">
                        <button @click="deleteArticle()" class="custom-btn btn-outline-red p-3 rounded-1 w-100">
                            {{$t("deleteButton")}}
                        </button>
                    </div>
                    <!-- <div class="col">
                        <button class="custom-btn btn-outline-blue p-3 rounded-1 w-100">
                            {{$t("previewButton")}}
                        </button>
                    </div> -->
                    <div class="col">
                        <button @click="(isCreator) ? createArticle() : updateArticle()" class="custom-btn btn-blue p-3 rounded-1 w-100">
                            {{$t(`${(isCreator) ? 'createButton' : 'saveButton'}`)}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<i18n>
{
    "en": {
        "title": "{en}",
        "createButton": "Create",
        "saveButton": "Save",
        "cancelButton": "Cancel",
        "deleteButton": "Delete",
        "previewButton": "Preview"
    },
    "th": {
        "title": "{th}",
        "createButton": "สร้าง",
        "saveButton": "บันทึก",
        "cancelButton": "ยกเลิก",
        "deleteButton": "ลบข่าว",
        "previewButton": "ตัวอย่าง"
    }
}
</i18n>
<style scoped>
.form-control {
    border-color: var(--lightGrey);
    font-weight: 300;
}
.form-control:focus {
    border-color: var(--navy);
    box-shadow: none;
}
</style>
<script>
import FormLanguage from './FormLanguage.vue'
import ImageUpload from './ArticleImageUpload.vue'

import CKEditor from '@ckeditor/ckeditor5-vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

import * as newsDb from '@/helpers/news.js'

export default {
    components: {
        FormLanguage,
        ImageUpload,
        CKEditor: CKEditor.component,
    },
    emits: [
        'submit'
    ],
    data() {
        return {
            modalElement: null,
            language: 'en',
            isCreator: true,
            article: {
                id: null,
                banner: {url: null, path: null}
            },
            editor: {
                type: ClassicEditor,
                config: {
                    toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', '|', 'indent', 'outdent', '|', 'numberedList', 'bulletedList', '|', 'insertTable', 'tableColumn', 'tableRow', 'mergeTableCells', '|', 'undo', 'redo']
                }
            },
            forms: {
                title: {
                    title: {
                        en: "Title", th: "หัวข้อ"
                    },
                    type: "input",
                    value: {
                        en: "", th: ""
                    }
                },
                banner: {
                    title: {
                        en: "Banner Image", th: "ภาพแบนเนอร์หลัก"
                    },
                    type: "image",
                    value: null,
                    url: null,
                    path: null
                },
                content: {
                    title: {
                        en: "Content", th: "เนื้อหา"
                    },
                    type: "ckeditor",
                    value: {
                        en: "", th: ""
                    }
                }
            },
            validation: {
                "hasEnTitle": {
                    title: {
                        en: "Article has an English title.",
                        th: "มีหัวข้อข่าวในภาษาอังกฤษ"
                    },
                    isValid: false
                },
                "hasContent": {
                    title: {
                        en: "Content was written in one or both languages.",
                        th: "เนื้อหาถูกเขียนด้วยภาษาใดภาษาหนึ่งหรือทั้งสอง"
                    },
                    isValid: false
                },
            }
        }
    },
    watch: {
        forms: {
            handler: "validate",
            deep: true
        }
    },
    async created() {
        const articleId = this.$route.query.id
        if (!!articleId) {
            const article = await newsDb.getSingleArticle(articleId)
            this.prepareData(article)
        }
    },
    mounted() {
        this.modalElement = document.getElementById('articleForm')
    },
    methods: {
        prepareData(data) {
            // Prepare form with data when data is assigned
            if (Object.keys(data).length) {
                this.isCreator = false
                this.article.id = data.path.split('-').pop()
                this.article.banner.url = data.banner.url
                this.article.banner.path = data.banner.path
                this.forms.title.value = data.title
                this.forms.content.value = data.body
            }
        },
        changeLanguage(lang) {
            this.language = lang
        },
        isTextForm(type) {
            return ['input', 'textarea', 'ckeditor'].includes(type)
        },
        closeForm() {
            // Go back to manager page
            this.$router.push({name: 'NewsManager'})
        },
        setBannerImage(blob) {
            this.forms.banner.value = blob
        },
        validate() {
            function isEnglish(value) {
                const ENGLISH = /^[A-Za-z0-9`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~\s]*$/
                return ENGLISH.test(value)
            }

            function notEmpty(value) {
                if (value === null) return false
                if (!value.length) return false
                return true
            }

            let title = this.forms.title.value
            let content = this.forms.content.value
            this.validation.hasEnTitle.isValid = notEmpty(title.en) && isEnglish(title.en)
            this.validation.hasContent.isValid = notEmpty(content.en) || notEmpty(content.th)
        },
        isValid() {
            let state = false
            for (const [key, validator] of Object.entries(this.validation)) {
                if (!validator.isValid) return state = false
                state = true
            }
            return state
        },
        async createArticle() {
            let title = this.forms.title.value
            let banner = this.forms.banner.value
            let content = this.forms.content.value
            let author = (await this._getUser()).uid
            try {
                if (!this.isValid()) throw Error("Error occur when creating news article.")
                const result = await newsDb.createArticle(title, banner, content, author)
                this.$emit('submit', {type: 'success', message: result})

            } catch(error) {
                this.$emit('submit', {type: 'error', message: error})
            }
            this.closeForm()
        },
        async updateArticle() {
            let id = this.article.id
            let title = this.forms.title.value
            let banner = this.forms.banner.value
            let oldBannerPath = this.article.banner.path
            let content = this.forms.content.value
            try {
                if (!this.isValid()) throw Error("Error occur when updating news article.")
                await newsDb.updateArticle(id, title, banner, oldBannerPath, content)
                this.$emit('submit', {type: 'success', message: result})
            } catch(error) {
                this.$emit('submit', {type: 'error', message: error})
            }
            this.closeForm()
        },
        async deleteArticle() {
            let id = this.article.id
            let bannerPath = this.article.banner.path
            try {
                const result = await newsDb.deleteArticle(id, bannerPath)
                this.$emit('submit', {type: 'success', message: result})
            } catch(error) {
                this.$emit('submit', {type: 'error', message: error})
            }
            this.closeForm()
        }
    }
}
</script>