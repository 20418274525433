<template>
    <div>
        <input @change="fileChange($event)" type="file" accept="image/png, image/gif, image/jpeg" class="form-control">
        <div v-if="source" class="ratio ratio-21x9 rounded-3 my-3 bg-secondary border border-lightgrey">
            <div>
                <Cropper ref="cropper" @change="change" :src="source" :stencil-size="setStencilSize" :stencil-props="setting.stencilProps" image-restriction="stencil" class="cropper" />
            </div>
        </div>
        <img v-else-if="bannerUrl" :src="bannerUrl" class="ratio ratio-21x9 rounded-3 my-3 border border-lightgrey">
        <div v-else class="ratio ratio-21x9 border border-lightgrey text-muted rounded-3 my-3">
            <span class="d-flex flex-column align-items-center justify-content-center">
                <i class="fas fa-image fa-5x"></i>
                <span class="small mt-2">{{$t('previewImage')}}</span>
            </span>
        </div>
    </div>
</template>
<i18n>
{
    "en": {
        "previewImage": "Click 'Choose File' to upload article banner. (Optional)"
    },
    "th": {
        "previewImage": "กด 'Choose File' เพื่ออัพโหลดรูปภาพแบนเนอร์ (Optional)"
    }
}
</i18n>
<style scoped>
.form-control {
    border-color: var(--lightGrey);
    font-weight: 300;
}
.form-control:focus {
    border-color: var(--navy);
    box-shadow: none;
}
.preview {
    border: 1px solid var(--lightGrey);
    border-radius: .3rem;
}
</style>
<script>
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

export default {
    components: {
        Cropper
    },
    props: {
        bannerUrl: {
            type: String,
            default: null
        }
    },
    emits: [
        'fileChange'
    ],
    data() {
		return {
            source: null,
            setting: {
                stencilProps: {
                    handlers: {},
                    movable: false,
                    resizable: false,
                    aspectRatio: 21/9
                }
            }
        }
	},
	methods: {
        setStencilSize({boundaries}) {
            return {
                width: boundaries.width,
                height: boundaries.height,
            }
        },
        fileChange(event) {
            const file = event.target.files[0]
            this.source = URL.createObjectURL(file)
        },
		change() {
			const { canvas } = this.$refs.cropper.getResult()
            if (!canvas) return
            canvas.toBlob(blob => {
                this.$emit('fileChange', blob)
            }, 'image/png')
		}
	},
}
</script>