<template>
    <div>
        <div class="d-flex justify-content-center text-muted small">
            <div @click="selectLanguage(key)" v-for="(lang, key) in languages" :key="key" :class="{active: selected === key}" class="btn-lang d-flex align-items-center mx-4">
                <i :class="lang.icon" class="fa-2x"></i>
                <span class="ms-2">{{lang.text}}</span>
            </div>
        </div>
    </div>
</template>
<style scoped>
.btn-lang {
    transition: .25s;
    cursor: pointer;
}
.active {
    color: var(--navy);
}
</style>
<script>
export default {
    props: {
        selected: {
            type: String,
            required: true,
            default: 'en'
        }
    },
    emits: [
        'languageChange'
    ],
    data() {
        return {
            selectedLanguage: null,
            languages: {
                en: {
                    icon: "fas fa-globe-americas",
                    text: "English",
                },
                th: {
                    icon: "fas fa-globe-asia",
                    text: "ภาษาไทย",
                }
            }
        }
    },
    methods: {
        selectLanguage(lang) {
            this.$emit('languageChange', lang)
        }
    }
}
</script>